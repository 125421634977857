import React from 'react'
import {Nullable, Optional} from '../TypeHelper'

type Props = {
  postCode?: string
}

export const formatPostCode = (postCode: Optional<string>): Nullable<string> => {
  if (postCode?.length !== 7) {
    return null
  }
  if (isNaN(Number(postCode))) {
    return null
  }
  return `${postCode.slice(0, 3)}-${postCode.slice(3, 7)}`
}

export const PostCodeInformation = (props: Props): JSX.Element => {
  const formattedPostCode = formatPostCode(props.postCode)

  if (!formattedPostCode) {
    return <></>
  }

  return <>{formattedPostCode}</>
}
