import {UsingMyCreditCardInfo} from '../Props/ContractInputInfo'

export class UsingMyCreditCardInfoDTO implements UsingMyCreditCardInfo {
  constructor(
    public cardId: string,
    public cardNumber: string,
    public expireDate: string,
    public isInUse: boolean,
  ) {
  }

  static fromJSON = (jsonObject: UsingMyCreditCardInfo): UsingMyCreditCardInfoDTO => {
    return new UsingMyCreditCardInfoDTO(
      jsonObject.cardId,
      jsonObject.cardNumber,
      jsonObject.expireDate,
      jsonObject.isInUse,
    )
  }
}