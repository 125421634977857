import {DomToReact, Nullable} from '../../Common/TypeHelper'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import parse, {attributesToProps, DOMNode, domToReact, HTMLReactParserOptions} from 'html-react-parser'


export interface AgreementJSON {
  id: number,
  contentHtml: string,
  issuanceDatetime: Nullable<string>,
}

export type Agreement = AgreementJSON

const StyledAgreementH1 = styled.h1`
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
`
const StyledAgreementH2 = styled.h2`
  font-size: 11px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 4px;
  margin-bottom: 4px;
`
const StyledAgreementH6 = styled.h6`
  font-size: 11px;
  font-weight: normal;
  line-height: 1.5;
  text-align: right;
  font-weight: normal;
`
const StyledAgreementP = styled.p`
  font-size: 11px;
  margin-bottom: 12px;
`
const StyledAgreementOl = styled.ol`
  font-size: 11px;
  > li {
    margin-bottom: 4px;
    text-indent: -12px;
    padding-left: 16px;
    list-style-type: decimal;
    list-style-position: inside;
    > ul {
      > li {
        margin-bottom: 4px;
        text-indent: -18px;
        padding-left: 16px;
        list-style: none;
        > ul {
          > li {
            margin-bottom: 4px;
            text-indent: -18px;
            padding-left: 16px;
            list-style: none;
          }
        }
      }
    }
  }
  margin-bottom: 16px;
`
const StyledAgreementUl = styled.ul`
  font-size: 11px;
  > li {
    margin-bottom: 4px;
    padding-left: 16px;
    list-style: none;
    > ul {
      > li {
        margin-bottom: 4px;
        text-indent: -18px;
        padding-left: 16px;
        list-style: none;
      }
    }
  }
  margin-bottom: 16px;
`

class AgreementDTO {
  id: number
  contentHtml: DomToReact
  issuanceDatetime: Nullable<dayjs.Dayjs>

  constructor(id: number, contentHtml: DomToReact, issuanceDatetime: Nullable<dayjs.Dayjs>) {
    this.id = id
    this.contentHtml = contentHtml
    this.issuanceDatetime = issuanceDatetime
  }

  static fromJSON(jsonObject: AgreementJSON): AgreementDTO {
    const issuanceDatetime = jsonObject.issuanceDatetime

    const parseOption: HTMLReactParserOptions = {
      trim: true,
      replace: (domNode: DOMNode): JSX.Element | DOMNode => replaceDomNode(domNode),
    }
    const parsedContent: DomToReact = parse(
      jsonObject.contentHtml,
      parseOption,
    )

    return new AgreementDTO(
      jsonObject.id,
      parsedContent,
      issuanceDatetime ? dayjs(issuanceDatetime) : null,
    )
  }
}

const replaceDomNode = (domNode: DOMNode): JSX.Element | DOMNode => {
  if ('type' in domNode && domNode.type === 'tag') {
    if ('name' in domNode && domNode.name === 'h1') {
      const {children} = domNode
      const props = attributesToProps(domNode.attribs)
      return <StyledAgreementH1 {...props}>{domToReact(children)}</StyledAgreementH1>
    }
    if ('name' in domNode && domNode.name === 'h2') {
      const {children} = domNode
      const props = attributesToProps(domNode.attribs)
      return <StyledAgreementH2 {...props}>{domToReact(children)}</StyledAgreementH2>
    }
    if ('name' in domNode && domNode.name === 'h6') {
      const {children} = domNode
      const props = attributesToProps(domNode.attribs)
      return <StyledAgreementH6 {...props}>{domToReact(children)}</StyledAgreementH6>
    }
    if ('name' in domNode && domNode.name === 'p') {
      const {children} = domNode
      const props = attributesToProps(domNode.attribs)
      return <StyledAgreementP {...props}>{domToReact(children)}</StyledAgreementP>
    }
    if ('name' in domNode && domNode.name === 'ol') {
      const {children} = domNode
      const props = attributesToProps(domNode.attribs)
      return <StyledAgreementOl {...props}>{domToReact(children)}</StyledAgreementOl>
    }
    if ('name' in domNode && domNode.name === 'ul') {
      const {children} = domNode
      const props = attributesToProps(domNode.attribs)
      return <StyledAgreementUl {...props}>{domToReact(children)}</StyledAgreementUl>
    }
  }
  return domNode
}

export default AgreementDTO
