import React, {CSSProperties} from 'react'
import {Optional} from '../TypeHelper'

type PropsForMargin = {
  m?: string

  mx?: string
  my?: string

  mt?: string
  mb?: string
  mr?: string
  ml?: string
}

type PropsForPadding = {
  p?: string

  px?: string
  py?: string

  pt?: string
  pb?: string
  pr?: string
  pl?: string
}

export type SpacerProps = PropsForMargin & PropsForPadding & {
  dataTestId?: string
  children?: React.ReactNode
}
export const Spacer: React.VFC<SpacerProps> = (props) => {
  const marginTop: Optional<string> = props.mt || props.my || props.m
  const marginBottom: Optional<string> = props.mb || props.my || props.m
  const marginRight: Optional<string> = props.mr || props.mx || props.m
  const marginLeft: Optional<string> = props.ml || props.mx || props.m

  const paddingTop: Optional<string> = props.pt || props.py || props.p
  const paddingBottom: Optional<string> = props.pb || props.py || props.p
  const paddingRight: Optional<string> = props.pr || props.px || props.p
  const paddingLeft: Optional<string> = props.pl || props.px || props.p

  const style: CSSProperties = {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
  }

  const everyValueIsUndefined =
    Object.values(style).every(value => value === undefined)

  if (everyValueIsUndefined) {
    return <>{props.children}</>
  }
  return <div style={style} data-testid={props.dataTestId}>{props.children}</div>
}
