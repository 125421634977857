import dayjs from 'dayjs'

export const formatPrice = (price: number): string => {
  return price.toLocaleString()
}

export const formatDate = (date: dayjs.Dayjs): string => {
  return date.format('YYYY/MM/DD')
}

export const formatDateTime = (date: dayjs.Dayjs): string => {
  return date.format('YYYY/MM/DD HH:mm')
}
