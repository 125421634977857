export interface MailMagazine {
  id: number
  name: string
}

export interface MailMagazineJSON {
  id: number
  name: string
}

class MailMagazineDTO implements MailMagazine {
  id: number
  name: string

  constructor(id: number, name: string) {
    this.id = id
    this.name = name
  }

  static fromJSON(jsonObject: MailMagazineJSON): MailMagazineDTO {
    return new MailMagazineDTO(
      jsonObject.id,
      jsonObject.name,
    )
  }
}

export default MailMagazineDTO
