import {MBMemberError, MBMemberErrorTypes} from '../../Common/MBMemberError'
import {useError} from 'react-use'

export const useMBMemberError = (): (message: MBMemberErrorTypes) => void => {
  const throwError = useError()

  return (m: MBMemberErrorTypes): void => throwError(new MBMemberError(m))
}

export const useMBMemberErrorUnknown = (): (error: unknown) => void => {
  const throwError = useError()

  return (error: unknown): void =>
    throwError(new MBMemberError(error instanceof Error ? error.message : 'SystemError'))
}
