import {Nullable, Optional} from '../../Common/TypeHelper'
import MailMagazineDTO, {MailMagazine, MailMagazineJSON} from './MailMagazineDTO'
import {PageUrlInfo, UrlListItem} from '../Models/Types'
import {ListUtility} from '../Utils/ListUtility'
import BucketHeader, {BucketHeaderJSON} from './BucketHeader'
import {MBMemberError} from '../Common/MBMemberError'

export interface SignboardJSON {
  id: number | null
  signupKey: string
  signupName: string
  shopId: Nullable<string>
  urlList: UrlListItem[]
  mailMagazineList: MailMagazineJSON[]
  bucketHeaderList: BucketHeaderJSON[]
}

export interface Signboard {
  id: number | null
  signupKey: string
  shopId: Nullable<string>
  logoImgUrl: string
  signupReturnPageInfo: PageUrlInfo
  signupName: string
  mailMagazineList: MailMagazine[]
  mainService: BucketHeader
  freeServices: BucketHeader[]
}

export class SignboardDTO implements Signboard {
  constructor(
    public id: number | null,
    public signupKey: string,
    public shopId: Nullable<string>,
    public logoImgUrl: string,
    public signupReturnPageInfo: PageUrlInfo,
    public signupName: string,
    public mailMagazineList: MailMagazine[],
    public mainService: BucketHeader,
    public freeServices: BucketHeader[],
  ) {
  }

  static fromJSON(jsonObject: SignboardJSON): SignboardDTO {
    const logoImgUrl: Optional<string> = filteredLogoImg(jsonObject.urlList)?.url

    const signupReturnUrl = filteredSignupReturnUrl(jsonObject.urlList)

    const bucketHeaderList = jsonObject.bucketHeaderList.map(item => BucketHeader.fromJSON(item))
    if (bucketHeaderList === undefined || bucketHeaderList.length === 0)
      throw new MBMemberError('ServerError')

    return new SignboardDTO(
      jsonObject.id,
      jsonObject.signupKey,
      jsonObject.shopId,
      logoImgUrl || '',
      signupReturnUrl || '',
      jsonObject.signupName,
      jsonObject.mailMagazineList.map(item => MailMagazineDTO.fromJSON(item)),
      bucketHeaderList[0],
      2 > bucketHeaderList.length ? [] : bucketHeaderList.slice(1),
    )
  }
}

export const filteredLogoImg = (urlList: UrlListItem[]): Optional<UrlListItem> => {
  return ListUtility.findOne<UrlListItem>(
    urlList || [],
    item => item.type === 'logoImg',
  )
}

export const filteredSignupReturnUrl = (urlList: UrlListItem[]): PageUrlInfo => {
  const findSignupReturnUrl = ListUtility.findOne<UrlListItem>(
    urlList || [],
    item => item.type === 'signupReturnUrl',
  )

  return {
    url: findSignupReturnUrl?.url,
    name: findSignupReturnUrl?.name ?? '',
  }
}
