import styled from '@emotion/styled'

type MBLogoImgProps = {
  logoImgMaxHeight: string
  logoImgMaxWidth?: string
}
export const MBLogoImg = styled.img`
  max-height: ${({logoImgMaxHeight}: MBLogoImgProps): string => logoImgMaxHeight};
  max-width: ${({logoImgMaxWidth}: MBLogoImgProps): string => logoImgMaxWidth ?? 'none'};
`
