import styled from '@emotion/styled'
import {Typography} from '../Styles'
import React from 'react'

type CopyrightProps = {
  color: string
}
export const StyledCopyright = styled.span`
  ${Typography.Regular12};
  color: ${({color}: CopyrightProps): string => color};
`

export const Copyright: React.VFC<CopyrightProps> = (props) => {
  return (
    <StyledCopyright color={props.color}>{'© Hitachi Solutions, Ltd.'}</StyledCopyright>
  )
}
