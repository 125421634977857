import {BrowserCacheLocation, Configuration, SilentRequest,} from '@azure/msal-browser'
import {Nullable} from '../../Common/TypeHelper'
import {MBAdminError} from '../Utils/MBAdminError'
import {acquireAccessToken, initializeMsalInstance, isAuthEnabled} from '../../Common/common-msa'

const tenantName = process.env.REACT_APP_AUTH_MSAL_ADMINS_TENANT_NAME
const tenantId = process.env.REACT_APP_AUTH_MSAL_ADMINS_TENANT_ID
const clientId = process.env.REACT_APP_AUTH_MSAL_ADMINS_CLIENT_ID!
const domain = process.env.REACT_APP_AUTH_MSAL_ADMINS_CUSTOM_DOMAIN || `${tenantName}.b2clogin.com`
const policyId = process.env.REACT_APP_AUTH_MSAL_ADMINS_POLICY_ID

export const msalConfig: Nullable<Readonly<Configuration>> = isAuthEnabled() ? {
  auth: {
    clientId,
    authority: `https://${domain}/${tenantId}/${policyId}`,
    knownAuthorities: [domain],
    redirectUri: '/admins/redirect',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    tokenRenewalOffsetSeconds: 60
  }
} : null

export const adminsMsalInstance = msalConfig && initializeMsalInstance(msalConfig)

export const authRequest: SilentRequest = {
  scopes: [`https://${tenantName}.onmicrosoft.com/${clientId}/admins.api`],
}

export const acquireAdminsAccessToken = acquireAccessToken(
  adminsMsalInstance, authRequest, new MBAdminError('AuthError'))
