import {Configuration, EventType, PublicClientApplication} from '@azure/msal-browser'
import {SilentRequest} from '@azure/msal-browser/dist/request/SilentRequest'
import {Nullable} from './TypeHelper'

// 本来は定数値で良いがテストコードでmockしやすいためメソッドとして定義
export const isAuthEnabled = (): boolean =>
  'REACT_APP_AUTH' in process.env && process.env.REACT_APP_AUTH === 'msal'

// デバッグ用途: msalConfig.system.loggerOptionsに指定することでMSALが出力するログをconsole.logへ出力
// export const loggerOptions: LoggerOptions = {
//   loggerCallback: (logLevel, message): void => console.log(message),
//   piiLoggingEnabled: true,
//   logLevel: LogLevel.Verbose
// }

export const initializeMsalInstance = (
  msalConfig: Readonly<Configuration>
): PublicClientApplication => {
  const msalInstance = new PublicClientApplication(msalConfig)

  msalInstance.addEventCallback((event) => {
    if ((event.eventType === EventType.LOGIN_SUCCESS
        || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        || event.eventType === EventType.SSO_SILENT_SUCCESS)
      && event.payload && 'account' in event.payload && event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account)
    }
  })

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      msalInstance.setActiveAccount(null)
    }
  })

  return msalInstance
}

export const acquireAccessToken = (
  msalInstance: Nullable<PublicClientApplication>,
  authRequest: SilentRequest,
  mbError: Error
): () => Promise<string> => {
  return async (): Promise<string> => {
    if (!msalInstance) {
      throw mbError
    }
    return msalInstance.acquireTokenSilent(authRequest)
      .then(authenticationResult => authenticationResult.accessToken)
      .catch(async () => {
        await msalInstance.acquireTokenRedirect(authRequest)
        throw mbError
      })
  }
}
