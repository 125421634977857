import {MsalProvider} from '@azure/msal-react'
import React from 'react'
import {PublicClientApplication} from '@azure/msal-browser'
import {Nullable} from '../TypeHelper'

type Props = {
  msalInstance: Nullable<PublicClientApplication>,
  children: React.ReactNode,
}

export const CommonAuthProvider: React.VFC<Props> = (props) => {
  const {msalInstance, children} = props

  if (!msalInstance) {
    return <>{children}</>
  }

  return (
    <MsalProvider instance={msalInstance}>
      {children}
    </MsalProvider>
  )
}
