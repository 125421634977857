export interface MemberBucketOptionsJSON {
  subscribeMailMagazineIdList: number[]
}

export class MemberBucketOptionsDTO {
  subscribeMailMagazineIdList: number[]

  constructor(subscribeMailMagazineIdList: number[]) {
    this.subscribeMailMagazineIdList = subscribeMailMagazineIdList
  }

  static fromJSON(jsonObject: MemberBucketOptionsJSON): MemberBucketOptionsDTO {
    return new MemberBucketOptionsDTO(
      jsonObject.subscribeMailMagazineIdList,
    )
  }
}
