import {ContractCampaignType, ContractMethod, ContractTerm} from '../Props/ContractInputInfo'
import {Optional} from '../../Common/TypeHelper'

export interface PaymentMethodJSON {
  amount: number
  method: ContractMethod
  term: ContractTerm
  oneTimeAmount?: number
  oneTimeAmountLabel?: string
  campaignType: ContractCampaignType
}

export interface PaymentMethod {
  amount: number
  method: ContractMethod
  term: ContractTerm
  oneTimeAmount: Optional<number>
  oneTimeAmountLabel: Optional<string>
  campaignType: ContractCampaignType
}

export class PaymentMethodDTO implements PaymentMethod {
  amount: number
  method: ContractMethod
  term: ContractTerm
  oneTimeAmount: Optional<number>
  oneTimeAmountLabel: Optional<string>
  campaignType: ContractCampaignType

  constructor(
    amount: number,
    method: ContractMethod,
    term: ContractTerm,
    oneTimeAmount: Optional<number>,
    oneTimeAmountLabel: Optional<string>,
    campaignType: ContractCampaignType,
  ) {
    this.amount = amount
    this.method = method
    this.term = term
    this.oneTimeAmount = oneTimeAmount
    this.oneTimeAmountLabel = oneTimeAmountLabel
    this.campaignType = campaignType
  }

  static fromJSON(jsonObject: PaymentMethodJSON): PaymentMethodDTO {
    return new PaymentMethodDTO(
      jsonObject.amount,
      jsonObject.method,
      jsonObject.term,
      jsonObject.oneTimeAmount ?? undefined,
      jsonObject.oneTimeAmountLabel ?? undefined,
      jsonObject.campaignType,
    )
  }
}
