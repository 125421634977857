import React, {useEffect} from 'react'
import {InteractionStatus, PublicClientApplication} from '@azure/msal-browser'
import {Nullable} from '../TypeHelper'
import {isAuthEnabled} from '../common-msa'

type LogoutProps = {
  msalInstance: Nullable<PublicClientApplication>
  postLogoutRedirectUri?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throwMBError: (message: any) => void
  navigate?: () => void
  inProgress?: InteractionStatus
}
export const Logout: React.VFC<LogoutProps> = (props) => {
  const {msalInstance, postLogoutRedirectUri, throwMBError, navigate, inProgress} = props

  if (inProgress && inProgress !== InteractionStatus.None) {
    // MSALのlogoutRedirectがキャンセルされてしまう問題の対策 MSAL処理が実行中のためここでリターン
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4401
    return null
  }

  if (!isAuthEnabled() || !msalInstance) {
    if (navigate) {
      navigate()
    }
    return null
  }

  useEffect(() => {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri
    }).catch(() => {
      throwMBError('SystemError')
    })
  }, [msalInstance, postLogoutRedirectUri, throwMBError])

  return null
}
