import {Nullable} from '../../Common/TypeHelper'


export interface BucketMemberIdJSON {
  bucketId: number
  bucketMemberId: Nullable<string>
}

export class BucketMemberId {
  constructor(
    public bucketId: number,
    public bucketMemberId: Nullable<string>,
  ) {
  }

  static fromJSON(jsonObject: BucketMemberIdJSON): BucketMemberId {
    return new BucketMemberId(
      jsonObject.bucketId,
      jsonObject.bucketMemberId,
    )
  }
}
