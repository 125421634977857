import {Route, RouteProps} from 'react-router-dom'
import React from 'react'

interface Props extends RouteProps {
  title: string
  children?: never
  component?: never
}

export const RouteWithTitle: React.VFC<Props> = (props) => {
  const {title, ...rest} = props
  document.title = title
  return <Route {...rest}/>
}
