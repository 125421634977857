import {css} from '@emotion/react'
import {Color, Typography} from './Views/Styles'

export const DefaultFontFamily = `${[
      'Noto Sans JP',
      'Hiragino Kaku Gothic Pro',
      'Meiryo',
      'sans-serif'
    ].join(',')}`

export const AdminGlobalStyles = css`
  html, body {
    height: 100%;
  }
  #root {
    height: 100%;
  }
  body {
    ${Typography.Regular14};
    color: ${Color.NewGrey900};
    font-family: ${DefaultFontFamily}
  }
`
