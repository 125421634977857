import {MBMemberError} from '../Common/MBMemberError'
import {Optional} from '../../Common/TypeHelper'

export module ListUtility {
  export const findOne =<T>(
    array: T[],
    predicate: (value: T, index: number, array: T[]) => unknown,
  ):Optional<T> => {
    const filteredArray = array.filter(predicate)

    if (filteredArray.length > 1) {
      throw new MBMemberError('SystemError')
    }

    return filteredArray[0]
  }
}
