export interface BucketLeaveJSON {
  leavePageTopMessage: string,
  leaveAttentionList: string[]
}

export interface BucketLeave {
  leavePageTopMessage: string,
  leaveAttentionList: string[]
}

export class BucketLeaveDTO implements BucketLeave {
  leavePageTopMessage: string
  leaveAttentionList: string[]

  constructor(leavePageTopMessage: string, leaveAttentionList: string[]) {
    this.leavePageTopMessage = leavePageTopMessage
    this.leaveAttentionList = leaveAttentionList
  }

  static fromJSON(jsonObject: BucketLeaveJSON): BucketLeaveDTO {
    return new BucketLeaveDTO(
      jsonObject.leavePageTopMessage,
      jsonObject.leaveAttentionList,
    )
  }
}
